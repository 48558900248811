body {
  margin: 0;
  background-color: #f6f6f6;
}

*:not(.MuiSnackbar-root *, .MuiLoadingButton-root, .MuiLoadingButton-root *) {
  transition: 0.3s ease-in-out !important;
}

section {
  overflow: hidden;
}

#top > section:not(#fv) {
  opacity: 0;
  transform: translateY(100px);
  transition: 1s ease-in-out !important;
}

#top > section:not(#fv).appeared {
  opacity: 1;
  transform: translateY(0);
}

.menu-btn {
  position: relative;
}

.menu {
  position: absolute;
  top: 100%;
  right: 0;
  opacity: 0;
  /* transition: 0.3s ease-in-out !important; */
  padding-top: 4px;
}

.menu a:hover {
  background-color: transparent !important;
}

.menu-btn:hover .menu {
  opacity: 1;
}

/* .menu-btn > span {
  transition: 0.3s ease-in-out !important;
} */

.menu-btn:hover > span {
  rotate: -180deg;
}
